import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@states/store';
import { v4 as uuidv4 } from 'uuid';
import { ChatMessage } from '@utils/types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  setChatHistory,
  setChatStatus,
  setFirstMessagSent,
  setIsInputDisabled,
  setIsStreamFinished,
  setWaitingForBotReply,
  setIsFileSent,
  setShowFeedbackSuggestion,
  setIsInputSent,
  setUserInput,
  restartChat,
  setCurrentConversation,
  setFeedbackData,
  setSuggestedQuestions,
  isStreamingWaitingInProgress,
  setIsUploadEnabled,
  setIsQuestionInput,
  setFinalBotReply,
  setFrequentlyAskedQuestions,
  setShowNewChatSuggestion,
} from '@states/slices/chatbotSlice';
import chatbotService from '@services/chatbotService';
import { socket } from '@utils/socket';
import { setUrl } from '@states/slices/filePreviewSlices';
import LatestResponse from './components/LatestResponse';
import { FeedbackSuggestion, FeedbackMessage } from '@components/Feedback';
import MessageItem from '@components/MessageItem';
import { getFileSrc, handleDragOver, handleFileDrop } from '@utils/files';
import useCurrentSkin from '@hooks/useCurrentSkin';
import NewChatSuggestion from '@components/NewChatSuggestion';
import Watermark from '@components/Watermark';
import conversationService from '@services/conversationService';
import ChatBottomContainer from '@components/ChatBottom/ChatBottomContainer';
import './Chatbot.scss';
import analyticsService from '@/services/analyticsService';
import { getLeaderCookies } from '@/utils/analytics';
import SuggestedQuestions from '../SuggestedQuestions';
import { RESPONSE_STREAMING_ENABLED } from '@utils/constants';
import {
  getCurrentMessageIndex,
  getCustomChatbotReply,
  generateChatMessage,
  waitFor,
  setCurrentMessageIndex,
  useAutoFlow,
} from '@utils/mockUtils';
import { useIdleEventTimeout } from '@/hooks/useIdleEventTimeout';
import { useAutoScroll } from '@/hooks/useAutoScroll';

function Chatbot() {
  const {
    botImage,
    color1,
    currentLanguage,
    productSelection,
    variantSelection,
    isFreemiumModeEnabled,
    conversationFlow,
    userWaitSeconds,
    isUserAutoResponseEnabled,
    isIdleEventEnabled,
  } = useSelector((state: RootState) => state.settings);
  const {
    chatHistory,
    chatStatus,
    currentUser,
    currentConversation,
    latestResponse,
    isStreamFinished,
    isFirstMessageSent,
    isWaitingForBotReply,
    userInput,
    isQuestionInput,
    isInputSent,
    isFileSent,
    showFeedbackSuggestion,
    feedbackData,
    showNewChatSuggestion,
    isUploadEnabled,
    savedCookies,
    showSettings,
    iframeParentUrl,
  } = useSelector((state: RootState) => state.chatbot);

  const dispatch = useDispatch();
  const currentSkin = useCurrentSkin();
  const { url } = useSelector((state: RootState) => state.filePreview);
  const isInputStreamingDisabled = useSelector(isStreamingWaitingInProgress);
  const [transferConfirmationMsg, setTransferConfirmationMsg] =
    useState<ChatMessage | null>(null);
  const currentMessageIndex = getCurrentMessageIndex();
  const { handleIdleTimeout } = useIdleEventTimeout();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = useAutoScroll(chatContainerRef);

  // THE FOLLOWING FUNCTIONS ARE FOR THE MOCKED AUTO FLOW

  // **************************************************************

  // ## MOCK
  const isAutoFlow = useAutoFlow();

  // ## MOCK
  useEffect(() => {
    if (!isAutoFlow) return;
    async function sendNextUserMessage() {
      if (currentMessageIndex >= conversationFlow.length) {
        return;
      }
      const nextUserMessage = conversationFlow[currentMessageIndex];
      if (nextUserMessage?.pov === 'user') {
        await waitFor(userWaitSeconds * 1000, currentConversation);
        dispatch(setUserInput(nextUserMessage.text));
        if (nextUserMessage?.file) {
          dispatch(setUrl(nextUserMessage.file));
        }
        setCurrentMessageIndex('increase');
        dispatch(setIsInputSent(true));
      }
    }
    if (isUserAutoResponseEnabled && !showSettings && chatHistory.length > 0) {
      sendNextUserMessage();
    }
  }, [
    chatHistory,
    showSettings,
    conversationFlow,
    isUserAutoResponseEnabled,
    productSelection,
  ]);

  // ## MOCK
  useEffect(() => {
    if (!isAutoFlow) return;
    if (isInputSent === true && (userInput !== '' || url !== '')) {
      dispatch(setIsInputSent(false));
      const newUserMessage = generateChatMessage(userInput, url);
      dispatch(setUserInput(''));
      dispatch(setUrl(''));
      const newChatHistory = [...chatHistory, newUserMessage];
      dispatch(setChatHistory(newChatHistory));
      const nextMessagePov = conversationFlow[currentMessageIndex]?.pov;
      if (nextMessagePov === 'bot' || !isUserAutoResponseEnabled) {
        getMockBotReply(newChatHistory);
      }
    }
  }, [isInputSent]);

  // ## MOCK
  useEffect(() => {
    if (!isAutoFlow) return;
    if (!isFirstMessageSent) {
      dispatch(setFirstMessagSent(true));
      getMockBotReply([], true);
    }
    const nextMessagePov = conversationFlow[currentMessageIndex]?.pov;
    if (nextMessagePov === 'bot' && !isInputSent && isUserAutoResponseEnabled) {
      getMockBotReply(chatHistory);
    }
  }, [isFirstMessageSent, currentMessageIndex]);

  // ## MOCK
  async function getMockBotReply(
    newChatHistory: ChatMessage[],
    isFirstMessage: boolean = false
  ) {
    try {
      dispatch(setWaitingForBotReply(true));
      dispatch(setIsInputDisabled(true));
      const { botReply, messageId, chatStatus, botReplyFile } =
        await getCustomChatbotReply(isFirstMessage, currentConversation);

      if (botReply === '' && !botReplyFile) {
        dispatch(setWaitingForBotReply(false));
        dispatch(setIsInputDisabled(false));
        return;
      }
      const newBotMessage: ChatMessage = {
        id: messageId,
        text: botReply,
        file: botReplyFile,
        pov: 'bot',
        time: new Date().toISOString(),
      };
      dispatch(setFinalBotReply(newBotMessage));
      dispatch(setChatHistory([...newChatHistory, newBotMessage]));
      dispatch(setIsInputDisabled(false));

      dispatch(setChatStatus(chatStatus));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setWaitingForBotReply(false));
    }
  }

  // **************************************************************

  useEffect(() => {
    if (
      !isFirstMessageSent &&
      currentUser !== '' &&
      currentConversation !== '' &&
      !isAutoFlow
    ) {
      dispatch(setFirstMessagSent(true));
      handleFirstMessage();
    }
  }, [currentConversation, isFirstMessageSent]);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, latestResponse, feedbackData]);

  useEffect(() => {
    if (currentUser && currentUser !== '' && currentConversation === '') {
      fetchConversationHistory(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (chatStatus === 'FINISHED' && !isInputStreamingDisabled) {
      if (!feedbackData.userFeedback && !feedbackData.userRating) {
        dispatch(setShowFeedbackSuggestion(true));
        return;
      }
      dispatch(setShowNewChatSuggestion(true));
    }
  }, [chatStatus]);

  async function fetchConversationHistory(currentUser: string) {
    try {
      const {
        messages,
        is_active,
        chat_status,
        variant,
        conversation_id,
        feedback: userFeedback,
        rating: userRating,
        upload_enabled: uploadEnabled,
      } = await conversationService.getChatHistory(currentUser);
      if (!is_active) {
        dispatch(restartChat());
        return;
      }
      const history = messages.sort((a: any, b: any) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      const latestMessage = messages[messages.length - 1] || {};

      const latestSuggestions = latestMessage?.suggested_questions || [];
      const latestFrequentlyAskedQuestions = latestMessage?.frequently_asked_questions || [];
      dispatch(setChatHistory(history));
      dispatch(setChatStatus(chat_status));
      dispatch(setCurrentConversation(conversation_id));
      dispatch(setSuggestedQuestions(latestSuggestions));
      dispatch(setFrequentlyAskedQuestions(latestFrequentlyAskedQuestions));
      dispatch(setIsUploadEnabled(uploadEnabled));
      if (userRating) {
        dispatch(
          setFeedbackData({ userFeedback, userRating, submitted: true })
        );
      }

      if (variant !== variantSelection) {
        // Revive specific variant
        if (variantSelection === 'alternative') {
          console.info('Bot variant changed. Resending initial message.');
          dispatch(setFirstMessagSent(false));
          return;
        }
        await conversationService.updateConversation(conversation_id, {
          chat_variant: variantSelection,
        });
      }
    } catch (error: any) {
      toast.error(error.response.data);
    }
  }

  useEffect(() => {
    if (isFileSent) {
      handleUserInput('');
      dispatch(setIsFileSent(false));
    } else if (isInputSent && !isAutoFlow) {
      handleUserInput(userInput);
      dispatch(setIsInputSent(false));
    }
  }, [isFileSent, isInputSent]);

  useEffect(() => {
    if (!RESPONSE_STREAMING_ENABLED) return;
    if (transferConfirmationMsg && latestResponse.text === '') {
      dispatch(setChatHistory([...chatHistory, transferConfirmationMsg]));
      setTransferConfirmationMsg(null);
    }
  }, [isStreamFinished, latestResponse]);

  async function handleFirstMessage() {
    dispatch(setIsInputDisabled(true));
    const newUserMessage = {
      id: uuidv4(),
      text: '',
      pov: 'user',
      time: '',
    };
    dispatch(setIsStreamFinished(true));
    await getBotReply(newUserMessage, chatHistory, true);
    dispatch(setFinalBotReply(null));
    if (!RESPONSE_STREAMING_ENABLED) {
      dispatch(setIsInputDisabled(false));
    }
    if (isIdleEventEnabled) {
      handleIdleTimeout();
    }
  }

  async function handleUserInput(userMessage: string) {
    if (userMessage !== '' || url !== '') {
      const message_id = uuidv4();
      const newUserMessage: ChatMessage = {
        id: message_id,
        text: userMessage,
        file: url,
        pov: 'user',
        time: new Date().toISOString(),
      };
      if (userMessage !== '') {
        dispatch(setUserInput(''));
        dispatch(setIsQuestionInput(false));
        dispatch(setSuggestedQuestions([]));
        dispatch(setFrequentlyAskedQuestions([]));
      }
      if (url !== '') {
        dispatch(setUrl(''));
      }
      if (chatStatus === 'WITH_AGENT') {
        const data = newUserMessage;
        socket.emit('send_live_message', data);
      } else {
        const newChatHistory = [...chatHistory, newUserMessage];
        dispatch(setChatHistory(newChatHistory));
        await getBotReply(newUserMessage, newChatHistory);
      }
    }
  }

  async function getBotReply(
    userMessage: ChatMessage,
    newChatHistory: ChatMessage[],
    isFirstMessage: boolean = false
  ) {
    try {
      dispatch(setWaitingForBotReply(true));
      dispatch(setIsInputDisabled(true));
      const {
        botReply,
        botReplyFile,
        messageId,
        chatStatus,
        botLead,
        suggestedQuestions,
        uploadEnabled,
        postMessageData,
        FrequentlyAskedQuestions,
      } = await chatbotService.getChatbotReply(
        userMessage.id,
        userMessage.text,
        isQuestionInput,
        currentUser,
        currentConversation,
        currentLanguage.language,
        productSelection,
        variantSelection,
        isFirstMessage,
        userMessage.file
      );
      if (botReply === '') {
        dispatch(setWaitingForBotReply(false));
        dispatch(setIsInputDisabled(false));
        return;
      }
      const newBotMessage: ChatMessage = {
        id: messageId,
        text: botReply,
        file: botReplyFile,
        pov: 'bot',
        suggested_questions: suggestedQuestions,
        frequently_asked_questions: FrequentlyAskedQuestions,
        time: new Date().toISOString(),
      };
      dispatch(setFinalBotReply(newBotMessage));
      if (!RESPONSE_STREAMING_ENABLED || !isFirstMessageSent) {
        dispatch(setChatHistory([...newChatHistory, newBotMessage]));
        dispatch(setIsInputDisabled(false));
      }

      dispatch(setChatStatus(chatStatus));
      dispatch(setIsUploadEnabled(uploadEnabled));
      if (Array.isArray(suggestedQuestions)) {
        dispatch(setSuggestedQuestions(suggestedQuestions));
      }

      if (Array.isArray(FrequentlyAskedQuestions)) {
        dispatch(setFrequentlyAskedQuestions(FrequentlyAskedQuestions));
      }

      switch (chatStatus) {
        case 'TRANSFERRED':
          await handleChatTransfer(newBotMessage, newChatHistory);
          break;
        default:
          break;
      }

      if (botLead) {
        // TODO This is specific to Leader Pixel. We can return lead type from the backend and parse the cookies accordingly
        // TODO Make this a state (setBotLead)
        dispatch(setWaitingForBotReply(false));
        const cookies = getLeaderCookies(savedCookies);
        const leaderDataWithCookies = { ...botLead, ...cookies };
        await analyticsService.sendLeadToLeaderPixel(leaderDataWithCookies);
        const originalUrl = leaderDataWithCookies.theurl;
        window.parent.postMessage(
          {
            type: 'GMT_EVENT',
            action: 'lead_sent',
            data: leaderDataWithCookies,
          },
          originalUrl
        );
      }

      // TODO: For the Leumi POC in a mobile webview
      if (postMessageData) {
        if (typeof window.ReactNativeWebView !== 'undefined') {
          // postMessage to React Native WebView
          console.info('Posting message to React Native WebView');
          window.ReactNativeWebView.postMessage(
            JSON.stringify(postMessageData)
          );
        } else if (
          // postMessage to iframe parent
          typeof window.parent !== 'undefined' &&
          window.parent !== window
        ) {
          if (iframeParentUrl) {
            console.info('Posting message to iframe parent');
            window.parent.postMessage(postMessageData, iframeParentUrl);
          } else {
            console.info('Iframe parent URL was not found!');
          }
        } else {
          console.warn('Unable to detect WebView or iframe context');
        }
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error.response.data.error);
    } finally {
      dispatch(setWaitingForBotReply(false));
    }
  }

  async function handleChatTransfer(
    newBotMessage: ChatMessage,
    newChatHistory: ChatMessage[]
  ) {
    const response = await transferConversationToCustomerService(
      currentUser,
      currentConversation
    );
    const transferConfirmationMessage = {
      id: response.message.message_id,
      text: response.message.content,
      pov: response.message.sender,
      time: new Date().toISOString(),
    };
    setTransferConfirmationMsg(transferConfirmationMessage);
    if (!RESPONSE_STREAMING_ENABLED) {
      dispatch(
        setChatHistory([
          ...newChatHistory,
          newBotMessage,
          transferConfirmationMessage,
        ])
      );
      setTransferConfirmationMsg(null);
    }
  }

  async function transferConversationToCustomerService(
    currentUser: string,
    currentConversation: string
  ) {
    try {
      dispatch(setWaitingForBotReply(true));
      const response =
        await chatbotService.transferConversationToCustomerService(
          currentUser,
          currentConversation
        );
      dispatch(setWaitingForBotReply(false));
      return response;
    } catch (error: any) {
      dispatch(setWaitingForBotReply(false));
      toast.error(error.response.data.error);
    }
  }

  function getNextUserResponse(currentIndex: number) {
    const hasNextMessage = currentIndex < chatHistory.length - 1;
    const nextMessage = hasNextMessage ? chatHistory[currentIndex + 1] : null;

    return nextMessage?.pov === 'user' ? nextMessage.text : null;
  }

  return (
    <>
      <div
        className={currentSkin.css.chatContainer}
        ref={chatContainerRef}
        onDragOver={handleDragOver}
        onDrop={(event) =>
          handleFileDrop(event, dispatch, isUploadEnabled, currentLanguage)
        }
      >
        {chatHistory.map((message, index) => {
          const userResponse = getNextUserResponse(index);
          return (
            <MessageItem
              key={message.id}
              message={message}
              id={message.id}
              rating={message.rating ? message.rating : null}
              questions={message.suggested_questions}
              selected={userResponse}
            />
          );
        })}
        {isWaitingForBotReply && !latestResponse.text ? (
          <div className='chatting-sub-container'>
            <div
              className='image-container'
              style={
                currentSkin.flags.chatbotLogoBorder
                  ? { borderColor: color1 }
                  : {}
              }
            >
              <img src={getFileSrc(botImage)} alt='' />
            </div>
            <div className='chat-bubble'>
              <div className='typing'>
                <div className='dot' style={{ backgroundColor: color1 }}></div>
                <div className='dot' style={{ backgroundColor: color1 }}></div>
                <div className='dot' style={{ backgroundColor: color1 }}></div>
              </div>
            </div>
          </div>
        ) : null}
        {latestResponse.text ? (
          <div className='chatting-sub-container latest-response'>
            <div
              className='image-container'
              style={
                currentSkin.flags.chatbotLogoBorder
                  ? { borderColor: color1 }
                  : {}
              }
            >
              <img src={getFileSrc(botImage)} alt='' />
            </div>
            <div className='latest-message'>
              <div>
                <LatestResponse chatContainerRef={chatContainerRef} />
              </div>
              {isStreamFinished && latestResponse.text === '' ? (
                <span className='message-time'>{latestResponse.time}</span>
              ) : null}
            </div>
          </div>
        ) : null}
        {chatStatus !== 'FINISHED' && isInputStreamingDisabled && (
          <div className='container-input-spacer' />
        )}
        {chatStatus !== 'FINISHED' && !isInputStreamingDisabled && (
          <SuggestedQuestions />
        )}
        {feedbackData.submitted === true &&
        ((feedbackData.userFeedback !== '' &&
          feedbackData.userFeedback !== null) ||
          feedbackData.userRating !== null) ? (
          <FeedbackMessage />
        ) : null}
      </div>
      {showFeedbackSuggestion && <FeedbackSuggestion />}
      {showNewChatSuggestion ? <NewChatSuggestion /> : null}
      {chatStatus !== 'FINISHED' ? <ChatBottomContainer /> : null}
      {isFreemiumModeEnabled ? <Watermark /> : null}
    </>
  );
}

export default Chatbot;
